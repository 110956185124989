@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'AvenirNextLTPro-Regular';
  src: url('../public/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-Bold';
  src: url('../public/fonts/AvenirNextLTPro-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-It';
  src: url('../public/fonts/AvenirNextLTPro-It.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
} */

html,body {
  height: 100%
}

body {
  color: #000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.skeleton {
  @apply bg-ppi-skeleton rounded-md
}

/* Initially hide the element with opacity 0 */
.fade-in-element {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Adjust the duration and easing as needed */
}

/* Add a class to show the element with full opacity */
.fade-in-element.show {
  opacity: 1;
}


/* .font-bold {
  font-family: 'AvenirNextLTPro-Bold';
  line-height: 1;
  vertical-align: baseline;
}

.italic {
  font-family: 'AvenirNextLTPro-It';
} */

.ppi-table-row > div:last-child .border-b {
  border-bottom: none !important;
}

.ppi-table td {
vertical-align: top;
}

.ppi-table tr > td:first-child {
  font-weight: bold;
  padding-right: 0.5rem;
  text-align: right;
}

.ppi-table tr > td:last-child {
  padding-left: 0.5rem;
}

.card-skeleton {
  background-color: #E8E8E8;
  border-radius: 8px;
  animation: skeleton-loading linear;
  transition: opacity 0.3s ease-out;
}

@keyframes skeleton-loading-old {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}

@keyframes skeleton-loading {
  0% {
    transform: scaleX(0);
    transform-origin: left;
     }
  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}